import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUserData } from "../../service/apiService";

const Profile = () => {
  const [user, setUser] = useState([]);
  const profile = {
    name: "John Doe",
    email: "johndoe@example.com",
    bio: "Software developer with a passion for coding and learning new technologies.",
  };
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, [navigate]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    if (token) {
      try {
        const userData = await fetchUserData(token);
        setUser(userData?.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        localStorage.removeItem("token");
        navigate("/");
      }
    }
  };
  return (
    <section className="_profile-page">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="card p-4" style={{ width: "100%", height: "250px" }}>
            {user && (
              <div className="card-body text-center">
                <div className="d-flex align-items-center">
                  <strong className="user_title">Name :</strong>
                  <p className="card-text ms-2">{user.full_name}</p>
                </div>
                <div className="d-flex  mt-3 align-items-center">
                  <strong className="user_title">Email :</strong>
                  <p className="card-text ms-2">{user.email}</p>
                </div>
                <div className="d-flex  mt-3 align-items-center">
                  <strong className="user_title">Phone Number:</strong>
                  <p className="card-text ms-2">{user.phone_no}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
