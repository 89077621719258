import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faHeart } from "@fortawesome/free-solid-svg-icons";
import noImage from "../../../assert/Image/banner-2.jpg";
import Adbanner from "./Adbanner";
import PaginationIndex from "../../../components/Paginations/PaginationIndex";
import { getCampaignList } from "../../../service/apiService";

const CampageList = () => {
  const [campaign, setCampaign] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;
  const navigate = useNavigate();
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  useEffect(() => {
    getAllProducts();
  }, []);
  const getAllProducts = async () => {
    try {
      const response = await getCampaignList();
      const status = await response.status_code;
      if (status === 200) {
        const responseData = [
          {
            campaign_data: [
              {
                campaign_name: response.data.campaign_name,
                expire_date: response.data.expire_date,
                title: response.data.title,
                description: response.data.description,
                image_path: response.data.image_path,
              },
            ],
            key_name: "CAMP",
          },
        ];
        setCampaign(response.data.products_data);
        setBannerData(responseData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = campaign.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const singlePageRedirection = (id) => {
    navigate(`/product_detail`, { state: { id } });
  };
  const handleNextPage = () => {
    if (currentPage < Math.ceil(campaign.length / productsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const gotoFirstPage = () => {
    setCurrentPage(1);
  };
  return (
    <main>
      <section className="section_breadcrumb section_padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home /</Link>
                </li>
                <li>
                  <Link>Campaign</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {bannerData.length > 0 && (
        <>
          <div
            className="text-center mb-5"
            style={{ fontSize: "24px", fontWeight: "bold", color: "orange" }}
          >
            Campaign List
          </div>
          <Adbanner sections={bannerData} />
        </>
      )}
      <section className="section_p_f_fil_cont section_padding_py">
        <div className="container">
          <div className="row s_p_f_f_c_par">
            <div className="col-lg-10 s_p_f_f_c_right ">
              <div className="row prdct_card_par camphain">
                {currentProducts.map((product) => (
                  <div
                    className="col-md-3 sing_prod_card"
                    key={product.id}
                    onClick={() => singlePageRedirection(product.product_id)}
                  >
                    <div className="static">
                      <div className="s_p_c_img">
                        <img
                          src={
                            product.thumnail_image.endsWith("undefined") ||
                            product.thumnail_image == ""
                              ? noImage
                              : AppUrl + product.thumnail_image
                          }
                          alt={product.product_name}
                          style={{ width: "100%", height: "270px" }}
                        />
                        {/* <div className="star_review_par">
                        <div className="star_value">
                          4.2 <FontAwesomeIcon icon={faStar} />
                        </div>
                        <div className="total_review">1.9k</div>
                      </div> */}
                      </div>
                      <div className="s_p_c_con">
                        <h6>{product.brand_name}</h6>
                        <p>{product.product_name}</p>
                        <div className="price">
                          <span className="dis_pr">
                            Rs.
                            {product.discount_percentage != 0
                              ? product.discount_price
                              : product.original_price}
                          </span>
                          {product.discount_percentage != 0 ? (
                            <span>
                              <span className="og_pr">
                                Rs.{product.original_price}
                              </span>
                              <span className="dis_per">
                                {product.discount_percentage}% OFF
                              </span>
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="s_p_c_hov_con">
                      <img
                        src={
                          product.thumnail_image.endsWith("undefined") ||
                          product.thumnail_image == ""
                            ? noImage
                            : AppUrl + product.thumnail_image
                        }
                        alt={product.product_name}
                        style={{ width: "100%", height: "240px" }}
                      />
                      <div to="/" className="whishlist">
                        <FontAwesomeIcon icon={faHeart} />
                        WISHLIST
                      </div>
                      {/* <p>Sizes: {product.sizes.join(", ")}</p> */}
                      <div className="price">
                        <span className="dis_pr">
                          Rs.
                          {product.discount_percentage != 0
                            ? product.discount_price
                            : product.original_price}
                        </span>
                        {product.discount_percentage != 0 ? (
                          <span>
                            <span className="og_pr">
                              Rs.{product.original_price}
                            </span>
                            <span className="dis_per">
                              {product.discount_percentage}% OFF
                            </span>
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                ))}
              </div>
              <PaginationIndex
                totalProducts={campaign.length}
                productsPerPage={productsPerPage}
                currentPage={currentPage}
                gotoFirstPage={gotoFirstPage}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CampageList;
