// import './assets/css/style.css';
import "./assert/css/custom.css";
import "../src/assert/css/responsive.css";
import "@fontsource/cabin/400-italic.css"; 
import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setSettingsData } from "./redux/Slice/settingSlice";
import { SettingApiData } from "./service/apiService";
// import  Productdetail from "./pages/views/Product/Testproduct";
// import  About from "./pages/views/About/about";

import useSecurity from "./components/Security/Security";
import ShopPage from "./pages/views/Shop/ShopPage";

import PrivateRoute from "./components/PrivateRoutes/PrivateRoute";
import CategoryPage from "./pages/views/Shop/CategoryPage";
import ContactUs from "./components/ContactUs/ContactUs";
import PreOrderPage from "./pages/views/PreOrder/PreOrderPage";
import Orders from "./components/Orders/Orders";
import OrdersList from "./components/Orders/OrderList";
import OrderDetails from "./components/Orders/OrderDetails";
import CampageList from "./pages/views/CampageList/CampageList";
import OrderAddressPage from "./components/Orders/OrderAddressPage";
import Profile from "./components/Orders/Profile";
import ChangePassword from "./components/Orders/ChangePassword";
const SubPages = lazy(() => import("./pages/views/SubPage/SubPage"));

const Home = lazy(() => import("./pages/views/Home/Home"));
const Login = lazy(() => import("./pages/Auth/LoginPage"));
const Header = lazy(() => import("./components/Header/Header"));
const BlogsList = lazy(() => import("./pages/views/Blogs/BlogsList"));
const BlogsDetailsPage = lazy(() =>
  import("./pages/views/Blogs/BlogsDetailsPage")
);
const Wishlist = lazy(() => import("./pages/views/Wishlist/Wishlist"));
const CartPage = lazy(() => import("./pages/views/Checkout/CartPage"));
const Footer = lazy(() => import("./components/Footer/Footer"));
const Shop = lazy(() => import("./pages/views/Shop/Shop"));
const Productdetail = lazy(() => import("./pages/views/Product/Testproduct"));
const Faq = lazy(() => import("./pages/views/faq"));
const Page = lazy(() => import("./pages/views/Page/Page"));

function App() {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  //  useSecurity();

  const OtpPage = lazy(() => import("./pages/views/Auth/OtpPage"));
  const ForgotPassWordPage = lazy(() =>
    import("./pages/views/Auth/ForgotPasswordPage")
  );
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    getSettingData();
  }, []);
  const getSettingData = async () => {
    try {
      const setting = await SettingApiData();
      dispatch(setSettingsData(setting.data));
    } catch (error) {
      console.error("Error fetching settings data:", error);
    }
  };
  const settingsData = useSelector((state) => state.settings.settingsData);
  const siteLogo = settingsData.find(
    (setting) => setting.key_name === "FAV_ICON"
  );
  const siteName = settingsData.find(
    (setting) => setting.key_name === "SITE_NAME"
  );
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = AppUrl + siteLogo?.key_value;
  }, [siteLogo?.key_value]);
  useEffect(() => {
    document.title = siteName?.key_value;
  }, [siteName?.key_value]);
  const isHeaderAndFooterRequired = ![
    "/invoice",
    "/checkout/address",
    "/design",
    "/design/mens",
    "/design/womens",
    "/design/home-and-living",
    "/design/beauty",
    "/design/electronic",
    "/design/explore",
    "/otp-verify",
    "/checkout/preorder",
  ].includes(location.pathname);
  return (
    <>
      {isHeaderAndFooterRequired && <Header />}
      <Suspense
        fallback={
          <div
            className="vh-100 d-flex justify-content-center"
            style={{ background: "#F3F5F0" }}
          >
            {/* <Loading /> */}
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Shop />} />
          <Route
            path="/checkout/cart"
            element={
              <PrivateRoute>
                <CartPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/wishlist"
            element={
              <PrivateRoute>
                <Wishlist />
              </PrivateRoute>
            }
          />
          <Route path="/product_detail" element={<Productdetail />} />
          {/* <Route path="/about" element={<About/>} /> */}
          <Route path="/otp" element={<OtpPage />} />
          <Route path="/forgot-password" element={<ForgotPassWordPage />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/page/:slug" element={<Page />} />

          <Route path="/sub-pages" element={<SubPages />} />
          {/* <Route path='/page/:slug' element={<Page />} />
          <Route path="/design" element={<Design />} />
          <Route path="/design/mens" element={<Men />} />
          <Route path="/design/women" element={<Women />} />
          <Route path="/design/womens" element={<Womens />} />
          <Route path="/design/home-and-living" element={<HomeLiving />} />
          <Route path="/design/electronic" element={<Electronic />} />
          <Route path="/design/beauty" element={<Beauty />} />
          <Route path="/order-details" element={<DhashboardPage />} />
          <Route path="/my/ordersPage/:orderId" element={<OrderDetailsPage />} />
          <Route path="/invoice/:orderId" element={<PrivateRoute><Invoice /></PrivateRoute>} />
          <Route path="/register" element={<Register />} /> */}
          <Route path="/login" element={<Login />} />
          {/* <Route path="/password/reset" element={<ForgotPassword />} />
          <Route path="/otp-verify" element={<OtpPage />} />
          <Route path="/wishlist" element={<PrivateRoute><Wishlist /></PrivateRoute>} />
          <Route path="/category" element={<MainCategory />} />
          <Route path="/shop" element={<ProductIndex />} />
          <Route path='/shop/:categoryName' element={<CategoryPage />} />
          <Route path="/product_detail" element={<Productdetail />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<ContactUs />/cartivateRoute><Preordercart /></PrivateRoute>} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/blogs' element={<BlogsList />} />
          <Route path='/blogs-details' element={<BlogDetails />} />
         
          <Route path="/checkout/address" element={<PrivateRoute><CheckoutAddress /></PrivateRoute>} />
          <Route path="*" element={<PageNotFound />} />
          <Route path='/explore' element={<ExplorePage />} />
          <Route path='/sub-pages' element={<SubPages />} /> */}
          <Route path="/blogs" element={<BlogsList />} />
          <Route path="/blogs-details" element={<BlogsDetailsPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/shop/:categoryName" element={<CategoryPage />} />

          <Route
            path="/orderList"
            element={
              <PrivateRoute>
                <OrdersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/address-book"
            element={
              <PrivateRoute>
                <OrderAddressPage />
              </PrivateRoute>
            }
          />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/pre-order"
            element={
              <PrivateRoute>
                {" "}
                <PreOrderPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/view"
            element={
              <PrivateRoute>
                <OrderDetails />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route path="/campaign-list" element={<CampageList />} />
        </Routes>
      </Suspense>
      <ToastContainer
        toastStyle={{
          fontSize: "15px",
          fontWeight: "bold",
          color: "white",
          backgroundColor: "#A7967E",
          borderRadius: "5px",
          padding: "10px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
          width: "800px",
          maxWidth: "100%",
          margin: "0 auto",
          textAlign: "center",
          height: "80px",
          minHeight: "80px",
        }}
        toastClassName="custom-toast"
      />
      {isHeaderAndFooterRequired && (
        <Suspense fallback={""}>
          <Footer />
        </Suspense>
      )}
    </>
  );
}
export default App;
