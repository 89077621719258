import React from "react";
import { Navigate } from "react-router-dom";

const isAuthenticated = () => {
    return localStorage.getItem("token");
    // return localStorage.getItem("token");
    // return !!localStorage.getItem("authToken");
};

const PrivateRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
