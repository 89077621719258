import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { changePassword, logoutService } from "../../service/apiService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = localStorage.getItem('token')
const navigate = useNavigate()
  const handleSubmit = async(e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      const response = await changePassword(token, newPassword )
      if(response.status_code === 200){
        toast.success('Password changed successfully!')
        localStorage.removeItem('token')
        navigate('/login')
        
        setNewPassword('')
        setConfirmPassword('')
      }
    } else {
      toast.error("Passwords do not match!");
    }
  };

  return (
    <>
      <div className="change-pwd padding-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="form-group mb-3">
                <label htmlFor="newPassword"><h5 className="mb-1"><strong>New Password</strong></h5></label>
                <input
                  type="password"
                  className="form-control rounded"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter new password"
                />
              </div>
              <div className="form-group ">
                <label htmlFor="confirmPassword"><h5 className="mb-1"><strong>Confirm Password</strong></h5></label>
                <input
                  type="password"
                  className="form-control rounded"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm new password"
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  className="btn btn-primary mt-3 mb-3"
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
