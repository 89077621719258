import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  cancelOrder,
  orderReturnRequest,
  getmyOrderListDetails,
} from "../../service/apiService";
import OrderImage from "../../assert/Image/pngwing.com.png";
import { downloadInvoice } from "./downloadInvoice";
import { useSelector } from "react-redux";
import "../../assert/css/orderDetails.css";

const OrderDetails = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const settingsData = useSelector((state) => state.settings.settingsData);
  const loginLogo = settingsData.find(
    (setting) => setting.key_name === "SITE_LOGO"
  );
  const phoneNumberone = settingsData.find(
    (item) => item.key_name === "PHONE_ONE"
  )?.key_value;
  const emailOne = settingsData.find(
    (item) => item.key_name === "EMAIL_ONE"
  )?.key_value;
  const phoneNumbertwo = settingsData.find(
    (item) => item.key_name === "PHONE_TWO"
  )?.key_value;
  const emailTwo = settingsData.find(
    (item) => item.key_name === "EMAIL_TWO"
  )?.key_value;
  let loginPageImg = "";
  if (loginLogo && loginLogo.key_value) {
    loginPageImg = AppUrl + loginLogo.key_value;
  }
  const location = useLocation();
  const { id } = location.state || {};
  const [orderReturnState, setOrderReturnState] = useState(true);
  const [cancelOrderState, setCancelOrderState] = useState(true);
  const token = localStorage.getItem("token");
  const [productDetails, setProductDetails] = useState([]);

  const vieworderDetailPage = async () => {
    try {
      const response = await getmyOrderListDetails(id, token);
      setProductDetails(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    vieworderDetailPage();
  }, []);

  const handleReturnRequest = (order_code) => {
    orderReturnRequest(order_code, token);
    setOrderReturnState(false);
  };

  const handleCancelOrder = (order_code) => {
    cancelOrder(order_code, token);
    setCancelOrderState(false);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "unpaid":
        return "badge bg-warning d-flex flex-column col-sm-2";
      case "refund":
        return "badge bg-primary d-flex flex-column col-sm-2";
      case "paid":
        return "badge bg-success title col-sm-2";
      default:
        return "badge bg-secondary title col-sm-2";
    }
  };
  const addressLinkOne = settingsData.find(
    (item) => item.key_name === "ADDRESS_ONE"
  )?.key_value;
  const handleDownload = async (orderId) => {
    await downloadInvoice(
      orderId,
      loginPageImg,
      addressLinkOne,
      phoneNumberone,
      phoneNumbertwo,
      emailOne,
      emailTwo
    );
  };

  return (
    <div className="col-xl-12 account-wrapper">
      <div className="account-card order-details">
        <div className="order-head">
          {/* <div className="head-thumb">
            <img src={OrderImage} alt="" />
          </div> */}
          <div className="clearfix m-l20">
            <h4 className="mb-0">{productDetails?.order_code}</h4>
          </div>
        </div>

        {/* Address Details Section */}
        <div className="row mb-sm-4 mb-2">
          <div className="col-md-6">
            <div
              className="address-section"
              style={{ background: "none", border: "none" }}
            >
              <h5>Shipping Address</h5>
              <div className="address-detail">
                <p>
                  <strong>Name:</strong>{" "}
                  {productDetails?.shipping_address?.shipping_name}
                </p>
                <p>
                  <strong>Address:</strong>{" "}
                  {productDetails?.shipping_address?.shipping_address_line_one}
                </p>
                <p>
                  <strong>City:</strong>{" "}
                  {productDetails?.shipping_address?.shipping_city}
                </p>
                <p>
                  <strong>State:</strong>{" "}
                  {productDetails?.shipping_address?.shipping_state}
                </p>
                <p>
                  <strong>Postal Code:</strong>{" "}
                  {productDetails?.shipping_address?.shipping_pincode}
                </p>
                <p>
                  <strong>Country:</strong>{" "}
                  {productDetails?.shipping_address?.shipping_country}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="address-section"
              style={{ background: "none", border: "none" }}
            >
              <h5>Billing Address</h5>
              <div className="address-detail">
                <p>
                  <strong>Name:</strong>{" "}
                  {productDetails?.billing_address?.billing_name}
                </p>
                <p>
                  <strong>Address:</strong>{" "}
                  {productDetails?.billing_address?.billing_address_line_one}
                </p>
                <p>
                  <strong>City:</strong>{" "}
                  {productDetails?.billing_address?.billing_city}
                </p>
                <p>
                  <strong>State:</strong>{" "}
                  {productDetails?.billing_address?.billing_state}
                </p>
                <p>
                  <strong>Postal Code:</strong>{" "}
                  {productDetails?.billing_address?.billing_pincode}
                </p>
                <p>
                  <strong>Country:</strong>{" "}
                  {productDetails?.billing_address?.billing_country}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* New Card Section */}
        <div className="row mb-sm-4 mb-2">
          <div className="col-lg-6 d-flex">
            <div className="card flex-fill" style={{ border: "none" }}>
              <div className="card-body">
                <h5 className="card-title">Tax Invoice</h5>
                <div className="card-text">
                  {/* <p><strong>GSTIN Number:</strong> {productDetails?.customer_name}</p> */}
                  {/* <p><strong>Invoice Number:</strong> {productDetails?.customer_email}</p> */}
                  <p>
                    <strong>Order Number:</strong> {productDetails?.order_code}
                  </p>
                  <p>
                    <strong>Mode of Payment:</strong> Online
                  </p>
                  <p>
                    <strong>Total Item:</strong> {productDetails?.total_count}
                  </p>
                  <p>
                    <strong>Payment Status:</strong>{" "}
                    {productDetails?.payment_status}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex">
            <div className="card flex-fill" style={{ border: "none" }}>
              <div className="card-body">
                <h5 className="card-title">Order Summary</h5>
                <div className="card-text">
                  <p>
                    <strong>Name:</strong> {productDetails?.customer_name}
                  </p>
                  <p>
                    <strong>Email:</strong> {productDetails?.customer_email}
                  </p>
                  <p>
                    <strong>Total Products:</strong>{" "}
                    {productDetails?.total_products}
                  </p>
                  <p>
                    <strong>Order Status:</strong>{" "}
                    {productDetails?.order_status}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="d-flex justify-content-evenly">
          <div className="d-flex justify-content-end m-b25">
            <button
              className="btn btn-outline-primary m-b15 btnhover20"
              style={{ width: "150px" }}
              onClick={() => handleDownload(productDetails?.order_id)}
            >
              <strong>Invoice</strong>
            </button>
          </div>
        
          <div className="d-flex justify-content-end m-b15">
            {productDetails?.order_status === "delivered" &&
            productDetails?.payment_status === "paid" &&
            orderReturnState ? (
              <div className="d-flex justify-content-end m-b15">
                <button
                  className="btn btn-outline-primary m-b15 btnhover20"
                  onClick={() =>
                    handleReturnRequest(productDetails?.order_code)
                  }
                >
                  <strong>RETURN REQUEST</strong>
                </button>
              </div>
            ) : (
              (productDetails?.order_status === "pending" ||
                productDetails?.order_status === "processing" ||
                productDetails?.order_status === "shipping") &&
              productDetails?.order_status !== "cancelled" &&
              cancelOrderState && (
                <div className="d-flex justify-content-end m-b15">
                  <button
                    className="btn btn-outline-danger m-b15 btnhover20"
                    onClick={() =>
                      handleCancelOrder(productDetails?.order_code)
                    }
                  >
                    <strong>ORDER CANCEL</strong>
                  </button>
                </div>
              )
            )}
          </div>
        </div> */}
      </div>

      <div className="clearfix">
        <div className="dz-tabs mt-3 style-3">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link active"
              id="nav-Item-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-Item"
              role="tab"
              aria-controls="nav-Item"
              aria-selected="false"
            >
              Item Details
            </button>
          </div>
        </div>
        <div className="tab-content" id="nav-tabContent">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Discount Amount</th>
                <th>Total</th>
              </tr>
            </thead>
            {productDetails?.purchase_list?.map((item) => (
              <tbody key={item.product_name}>
                <tr>
                  <td>{item?.product_name}</td>
                  <td>Rs.{item?.price}</td>
                  <td>{item?.quantity}</td>
                  <td>Rs.{item?.discount_price}</td>
                  <td className="text-success">Rs.{item?.sales_amount}</td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        <div className="d-flex  justify-content-between total_amount_">
          <span className="t_ta">TOTAL AMOUNT</span>
          <span className="rs_amount"> Rs.{productDetails?.total_amount}</span>
        </div>
        <hr />
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="d-flex  m-b25 " style={{ gap: "10px" }}>
            <button
              className="btn btn-outline-primary m-b15 btnhover20"
              style={{ width: "150px" }}
              onClick={() => handleDownload(productDetails?.order_id)}
            >
              <strong>Invoice</strong>
            </button>
            {productDetails?.order_status === "delivered" &&
            productDetails?.payment_status === "paid" &&
            orderReturnState ? (
              <div className="d-flex justify-content-end m-b15">
                <button
                  className="btn btn-outline-primary m-b15 btnhover20 "
                  onClick={() =>
                    handleReturnRequest(productDetails?.order_code)
                  }
                >
                  <strong>RETURN REQUEST</strong>
                </button>
              </div>
            ) : (
              (productDetails?.order_status === "pending" ||
                productDetails?.order_status === "processing" ||
                productDetails?.order_status === "shipping") &&
              productDetails?.order_status !== "cancelled" &&
              cancelOrderState && (
                <div className="d-flex justify-content-end m-b15">
                  <button
                    className="btn btn-outline-danger m-b15 btnhover20"
                    onClick={() =>
                      handleCancelOrder(productDetails?.order_code)
                    }
                  >
                    <strong>ORDER CANCEL</strong>
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
