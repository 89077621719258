import { useEffect, useState } from "react"
import { getPreOrderListAPICall } from "../../../service/apiService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import emptyImage from '../../../assert/Image/emptycart.jpg';


export default function PreOrderPage() {
    const AppUrl = process.env.REACT_APP_IMAGE_URL;
    const [PreOrderData, setPreOrderData] = useState([])

    useEffect(() => {
        getPreOrderItems();
    }, [])

    const getPreOrderItems = async () => {
        try {
            const response = await getPreOrderListAPICall();

            if (response.status_code == 200) {
                setPreOrderData(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div>
                <h1 className="section-title">PRE-ORDER LIST</h1>
            </div>
            <main>
                <section className="section_payment section_cart_payment">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 cart_left">
                                <div className="cart_products">
                                    {
                                        PreOrderData.length ? (
                                            PreOrderData.map((product, index) => (
                                                <div key={index} className="sing_cart_product">
                                                    <img
                                                        src={ AppUrl + product.thumnail_image}
                                                        className="img-fluid"
                                                        alt=""
                                                        width={130}
                                                    />
                                                    <div className="s_c_p_content">
                                                        <p className="prod_des">
                                                            {product.product_name}
                                                        </p>
                                                        <div className="price_mrp_dis">
                                                            <span>
                                                                <FontAwesomeIcon icon={faIndianRupeeSign} />
                                                                &nbsp;{product.sub_total}
                                                            </span>
                                                            <span>
                                                                <FontAwesomeIcon icon={faIndianRupeeSign} />
                                                                &nbsp;{product.original_price}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-center">
                                                <img src={emptyImage} alt="" width={1000} />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </>)
}
